import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
     <>
     <div className="footer">
     <div className="container-fluid flwidth">
         <div className="row">
             <div className="col-lg-12">
                 <img src="https://webapi.entab.info/api/image/STMST/public/Images/footerlogo.png" />
             </div>
             <div className="col-xl-3 col-lg-6 col-md-6 divider">
                 <div className="quick-link">
                     <ul>
                         <li><Link to="AboutUs"> <i className="bi bi-caret-right-fill"></i> About Us</Link></li>
                         <li><Link to="Infrastructure"> <i className="bi bi-caret-right-fill"></i> Infrastructure</Link></li>
                         <li><Link to="Admission"> <i className="bi bi-caret-right-fill"></i> Admission</Link></li>
                         <li><Link to="FeeStructure"> <i className="bi bi-caret-right-fill"></i> Fee Structure</Link></li>
                     </ul>
                     <ul>
                         <li><Link to="/Pphelp"> <i className="bi bi-caret-right-fill"></i> Portal Help?</Link></li>
                        
                         <li><Link to="/ErpSoftware"> <i className="bi bi-caret-right-fill"></i> ERP Software</Link></li>
                         
                     </ul>
                 </div>
             </div>
             <div className="col-xl-3 col-lg-6 col-md-6">
                 <div className="cont-dtl">
                     <h3>Location</h3>
                     <p><Link to="https://maps.app.goo.gl/yEo5iEfY3BKTefVDA" target="_blank"> <i className="bi bi-geo-alt"></i> Sector- 82, Bethany Nagar, Bathola, Faridabad- 121004</Link></p>
                     <p><Link to="tel:9717528625"><i className="bi bi-telephone-fill"></i>+91 9717528625</Link></p>
                     <p><Link to="mailto:stmarysconventschool.1993@gmail.com"><i className="bi bi-envelope"></i> stmarysconventschool.1993@gmail.com</Link></p>
                     <p><Link to="https://stmarysfbd.edu.in/" target="_blank"><i className="bi bi-globe"></i> www.stmarysfbd.edu.in</Link></p>
                 </div>
             </div>
             
             <div className="col-lg-3 col-md-6 divider">
             <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.8223326961797!2d77.35406357621113!3d28.394433694851983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdce7f84d9c8f%3A0x52e40b2989fc93e0!2sSt.%20Mary&#39;s%20Convent%20Senior%20Secondary%20School%2C%20Bhatola%2CSector-82%2C%20Faridabad!5e0!3m2!1sen!2sin!4v1712741114261!5m2!1sen!2sin"  width="100%"  border="0px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
             <div className="col-xl-3 col-lg-6 col-md-6">
                 <div className="d-flex">
                     <div className="app-cont">
                         <p>Download mobile App</p>
                         <div>
                         <a href="https://apps.apple.com/in/app/campuscare-10x/id1611283814" target="_blank"> <img src="https://webapi.entab.info/api/image/STMST/public/Images/app1.png"/></a>
                         <a href="https://play.google.com/store/apps/details?id=com.entab.learninglab&amp;hl=en-IN" target="_blank"> <img src="https://webapi.entab.info/api/image/STMST/public/Images/app2.png"/></a>
                         </div>
                         <p className="link">
                            Use School Code : SMCTHR 
                         </p>
                     </div>
                     <div className="phone-img">
                         <img src="https://webapi.entab.info/api/image/STMST/public/Images/app3.png" />
                     </div>
                 </div>
             </div>
               <div className="copyright">
                   <p>©️2024 St. Mary’s Convent Sr. Secondary School | Designed and Developed by Entab Infotech : <Link to="#">CampusCare®️</Link></p>
               </div>
         </div>
     </div>
     
     </div> 
     </>
  )
}

export default Footer
